import React from 'react';
import { graphql } from 'gatsby';
import * as Ink from '@carta/ink';
import capitalize from 'lodash/capitalize';
import Layout from '../components/Layout';
import Header from '../components/Header/Header';
import PreviewCard from '../components/Common/PreviewCard';
import Content from '../components/Content/Content';
import StyledLink from '../components/Common/StyledLink';

import { MDXRendererWithMapping } from '../components/Common/MarkdownRenderers';
import TableOfContents from '../components/Common/TableOfContents';

const PatternPage = ({ data: { pattern, allPatterns } }) => {
  const getPatternType = () => {
    if (pattern.fileAbsolutePath.includes('patterns')) return 'patterns';
    if (pattern.fileAbsolutePath.includes('hooks')) return 'hooks';
    if (pattern.fileAbsolutePath.includes('guides')) return 'guides';
    return '';
  };

  return (
    <Layout pageTitle={pattern.frontmatter.title}>
      <Ink.Box bottom="medium">
        <Ink.Breadcrumb>
          <Ink.Breadcrumb.Element
            render={() => <StyledLink to={`/${getPatternType()}`}>{capitalize(getPatternType())}</StyledLink>}
          />
          {pattern.frontmatter.parent && (
            <Ink.Breadcrumb.Element
              render={() => (
                <StyledLink to={`/${getPatternType()}/${pattern.frontmatter.parent}`}>
                  {capitalize(pattern.frontmatter.parent)}
                </StyledLink>
              )}
            />
          )}
        </Ink.Breadcrumb>
      </Ink.Box>
      <Header title={pattern.frontmatter.title} subtitle={pattern.frontmatter.subtitle} />
      <Content accessory={<TableOfContents items={pattern.tableOfContents.items} />}>
        <MDXRendererWithMapping md={pattern.body} />

        {pattern.frontmatter.related && (
          <Ink.Box top="xlarge" bottom="xlarge">
            <Ink.Tiles columns={[1, 2, 2, 3]}>
              {allPatterns.nodes
                .filter(node => pattern.frontmatter.related.includes(node.slug))
                .map(item => (
                  <PreviewCard
                    key={item.frontmatter.title}
                    title={item.frontmatter.title}
                    text={item.frontmatter.subtitle}
                    actionLabel="View pattern"
                    actionHref={`/patterns/${item.slug}`}
                    previewWithCheckerboard
                  />
                ))}
            </Ink.Tiles>
          </Ink.Box>
        )}
      </Content>
    </Layout>
  );
};

export default PatternPage;

export const query = graphql`
  query PatternPage($slug: String!) {
    pattern: mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        title
        subtitle
        relatedContentCards
        related
        parent
      }
      slug
      tableOfContents
      fileAbsolutePath
    }
    allPatterns: allMdx(filter: { fileAbsolutePath: { regex: "/patterns/" } }) {
      nodes {
        frontmatter {
          title
          subtitle
        }
        slug
      }
    }
  }
`;
